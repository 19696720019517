import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img'; 
import API_URL from '../../config/config'; 
import Commoninformation from '../Pagehome/Commoninformation';
import Icons from '../../components/icons';
import { formatTitleForURL } from '../../function';

const Searchpage = () => {
  const { query } = useParams(); // Get the search term from the URL
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const fetchNews = async (offset) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_URL}get-search-news.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search_term: query,
          offset,
          limit: 8,
        }),
      });
      const data = await response.json();

      if (data.error === 0) {
        setNews((prevNews) => {
          const existingNewsIds = new Set(prevNews.map(item => item.id));
          const newNews = data.news.filter(item => !existingNewsIds.has(item.id));
          return [...prevNews, ...newNews];
        });
        setHasMore(data.news.length === 8);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setError('An error occurred while fetching news.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (query) {
      setNews([]); // Reset news
      setOffset(0);
      setLoadingSkeleton(true); // Show skeleton on new search
      fetchNews(0);
    }
  }, [query]);

  useEffect(() => {
    if (offset > 0) {
      fetchNews(offset);
    }
  }, [offset]);

  // Timer for skeleton loader
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingSkeleton(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      <Commoninformation />
      <div className='container-lg'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Search results for "{query}"
            </li>
          </ol>
        </nav>
      </div>
      <div className='category-section padding-50'>
        <div className='container-lg'>
          <div className='flex-box justify-center padding-bottom-50'>
            <div className='custom-heading text-center'>
              Search Results for "{query}"
            </div>
          </div>

          {isLoading && <div>Loading...</div>}
          {error && <div className='error-message'>{error}</div>}

          <div className='row'>
            <div className='col-md-12 row-gap'>
              <div className='row row-gap-4'>
                {loadingSkeleton ? (
                  // Skeleton Loader for News Items
                  [...Array(8)].map((_, index) => (
                    <div key={index} className='col-md-6'>
                      <div className='common-news-box'>
                        <div className='cnb-box'>
                          <div className='cnb-inner'>
                            <div className='cnb-img'>
                              <div className='skeletant-news-img sh-125'></div> {/* Image Skeleton */}
                            </div>
                            <div className='cnb-content'>
                              <div className='custom-heading ch-medium'>
                                <div className='skeletant-news-title sh-70'></div> {/* Title Skeleton */}
                              </div>
                              <div className='custom-text'>
                                <div className='skeletant-news-text sh-40'></div> {/* Text Skeleton */}
                              </div>
                              <div className='custom-text'>
                                <div className='skeletant-news-date sh-30'></div> {/* Date Skeleton */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : news.length === 0 ? (
                  <div>No news available.</div>
                ) : (
                  news.map(newsItem => (
                    <div key={newsItem.id} className='col-md-6'>
                      <div className='common-news-box'>
                        <div className='cnb-box'>
                          <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                            <div className='cnb-inner'>
                              <div className='cnb-img'>
                                <img
                                  src={newsItem.image || Img.Market4}
                                  alt={newsItem.title}
                                  style={{ width: '100%' }}
                                />
                              </div>
                              <div className='cnb-content'>
                                <div className='custom-heading ch-medium'>
                                  {newsItem.title}
                                </div>
                                <div className='custom-text'>
                                  {newsItem.sort_title}
                                </div>
                                
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Searchpage;
