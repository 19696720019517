import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img'; 
import API_URL from '../../config/config'; 
import Commoninformation from '../Pagehome/Commoninformation';
import Icons from '../../components/icons';
import { formatTitleForURL } from '../../function';

const Category = () => {
    const { category } = useParams();
    const [news, setNews] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [ads, setAds] = useState([]); 
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [currentAdIndex, setCurrentAdIndex] = useState(0); 
    const [isSticky, setIsSticky] = useState(false); 
    const [loadingSkeleton, setLoadingSkeleton] = useState(true);
     const [mainAds, setMainAds] = useState([]); // For 600x300 ads
    const [bannerAds, setBannerAds] = useState([]); // For 90x728 ads
     const [currentMainAdIndex, setCurrentMainAdIndex] = useState(0);
    const [currentBannerAdIndex, setCurrentBannerAdIndex] = useState(0);

    const fetchNews = async (offset) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-category.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    category,
                    offset,
                    limit: 8,
                }),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNews((prevNews) => {
                    const existingNewsIds = new Set(prevNews.map(item => item.id));
                    const newNews = data.news.filter(item => !existingNewsIds.has(item.id));
                    return [...prevNews, ...newNews];
                });
                setHasMore(data.news.length === 8);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setError('An error occurred while fetching news.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (category) {
            setNews([]); 
            setOffset(0);
            setLoadingSkeleton(true); // Show skeleton on category change
            fetchNews(0);
        const timeoutId = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 1500);
        }
    }, [category]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 100) {
                if (hasMore && !isLoading) {
                    setOffset(prevOffset => prevOffset + 8);
                }
            }

            setIsSticky(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore, isLoading]);

    useEffect(() => {
        if (offset > 0) {
            fetchNews(offset);
        }
    }, [offset]);


    const handleAdClose = () => {
        setIsAdVisible(false);
    };

      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };


    useEffect(() => {
        const fetchAds = async () => {
            try {
                const mainAdResponse = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 90, width: 728 })
                });
                const mainAdData = await mainAdResponse.json();

                if (mainAdData.error === 0) {
                    setMainAds(mainAdData.ads);
                } else {
                    setError(mainAdData.msg || 'Failed to fetch main advertisements.');
                }

                const bannerAdResponse = await fetch(`${API_URL}get-advertisements.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ height: 600, width: 300 })
                });
                const bannerAdData = await bannerAdResponse.json();

                if (bannerAdData.error === 0) {
                    setBannerAds(bannerAdData.ads);
                } else {
                    setError(bannerAdData.msg || 'Failed to fetch banner advertisements.');
                }
            } catch (error) {
                setError('An error occurred while fetching advertisements.');
            }
        };

        fetchAds();
    }, []);

    // useEffect(() => {
    //     let mainAdInterval;
    //     if (mainAds.length > 0 && isAdVisible) {
    //         mainAdInterval = setInterval(() => {
    //             setCurrentMainAdIndex((prevIndex) => (prevIndex + 1) % mainAds.length);
    //         }, 10000);
    //     }

    //     return () => clearInterval(mainAdInterval);
    // }, [mainAds, isAdVisible]);

    // useEffect(() => {
    //     let bannerAdInterval;
    //     if (bannerAds.length > 0 && isAdVisible) {
    //         bannerAdInterval = setInterval(() => {
    //             setCurrentBannerAdIndex((prevIndex) => (prevIndex + 1) % bannerAds.length);
    //         }, 10000);
    //     }

    //     return () => clearInterval(bannerAdInterval);
    // }, [bannerAds, isAdVisible]);

    // Timer for skeleton loader
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 3500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Commoninformation />
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {category}
                        </li>
                    </ol>
                </nav>
            </div>
            <div className='category-section padding-50'>
                <div className='container-lg'>
                    <div className='flex-box justify-center padding-bottom-50'>
                        <div className='custom-heading text-center'>
                            {capitalizeFirstLetter(category)} News
                        </div>
                    </div>

                    {isLoading && <div></div>}
                    {error && <div className='error-message'>{error}</div>}

                    <div className='row'>
                        <div className={`col-${isAdVisible ? 'md-9' : 'md-12'} row-gap`}>
                            <div className='row row-gap-4'>
                                {loadingSkeleton ? (
                                    // Skeleton Loader for News Items
                                    [...Array(8)].map((_, index) => (
                                        <div key={index} className='col-md-6'>
                                            <div className='common-news-box'>
                                                <div className='cnb-box'>
                                                    <div className='cnb-inner'>
                                                        <div className='cnb-img'>
                                                            <div className='skeletant-news-img sh-125'></div> {/* Image Skeleton */}
                                                        </div>
                                                        <div className='cnb-content'>
                                                            <div className='custom-heading ch-medium'>
                                                                <div className='skeletant-news-title sh-70'></div> {/* Title Skeleton */}
                                                            </div>
                                                            <div className='custom-text'>
                                                                <div className='skeletant-news-text sh-40'></div> {/* Text Skeleton */}
                                                            </div>
                                                            <div className='custom-text'>
                                                                <div className='skeletant-news-date sh-30'></div> {/* Date Skeleton */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : news.length === 0 ? (
                                    <div>No news available.</div>
                                ) : (
                                    news.map(newsItem => (
                                        <div key={newsItem.id} className='col-md-6'>
                                            <div className='common-news-box'>
                                                <div className='cnb-box'>
                                                    <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                        <div className='cnb-inner'>
                                                            <div className='cnb-img'>
                                                                <img
                                                                    src={newsItem.image || Img.Market4}
                                                                    alt={newsItem.title}
                                                                    style={{ width: '100%' }}
                                                                />
                                                            </div>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading ch-medium'>
                                                                    {newsItem.title}
                                                                </div>
                                                                <div className='custom-text'>
                                                                    {newsItem.sort_title}
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>

                             {isAdVisible && mainAds.length > 0 && (
                                <div className='common-advertisement margin-25 mb-0 d-none d-md-block'>
                                    <button className='close-btn' onClick={handleAdClose}>
                                        <Icons.XmarkSvg />
                                    </button>
                                    <Link className='adt-inner' to={mainAds[currentMainAdIndex].link}>
                                        <img src={mainAds[currentMainAdIndex].image} alt="Advertisement" />
                                    </Link>
                                </div>
                            )}
                        </div>

                        {isAdVisible && bannerAds.length > 0 && (
                            <div className='col-md-3'>
                                <div className={`adver-tisement d-none d-md-block ${isSticky ? 'sticky-ad' : ''}`}>
                                    <button className='close-btn' onClick={handleAdClose}>
                                        <Icons.XmarkSvg />
                                    </button>
                                    <Link className='adt-inner' to={bannerAds[currentBannerAdIndex].link}>
                                        <img src={bannerAds[currentBannerAdIndex].image} alt="Banner Advertisement" />
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Category;
