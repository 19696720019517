import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import Commoninformation from './Commoninformation';
import { formatTitleForURL } from '../../function';

const Politicsnews = () => {
    const [politicsNews, setPoliticsNews] = useState([]);
    const [ads, setAds] = useState([]); // State to store advertisements
    const [currentAdIndex, setCurrentAdIndex] = useState(0); // State for current ad index
    const [error, setError] = useState(null);
    const [isAdVisible, setIsAdVisible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true); // New state for skeleton loader

    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    // Fetch politics news from the API
    useEffect(() => {
        const fetchPoliticsNews = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${API_URL}get-politics-news.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({})
                });
                const data = await response.json();

                if (data.error === 0) {
                    setPoliticsNews(data.politics_news);
                } else {
                    setError(data.msg || 'Failed to fetch politics news.');
                }
            } catch (error) {
                setError('An error occurred while fetching politics news.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPoliticsNews();
    }, []); 

    // Fetch ads from the API
    useEffect(() => {
    const fetchAds = async () => {
        try {
            const response = await fetch(`${API_URL}get-advertisements.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ height: 600, width: 300 }) // Correctly sending dimensions
            });
            const data = await response.json();

            if (data.error === 0) {
                setAds(data.ads);
            } else {
                setError(data.msg || 'Failed to fetch advertisements.');
            }
        } catch (error) {
            setError('An error occurred while fetching advertisements.');
        }
    };

    fetchAds();
}, []);




    // Set up interval to change ads every 10 seconds
    useEffect(() => {
        let adInterval;
        if (ads.length > 0 && isAdVisible) {
            adInterval = setInterval(() => {
                setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
            }, 10000);
        }

        return () => clearInterval(adInterval);
    }, [ads, isAdVisible]);

    // Set up timeout to hide skeleton loader after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);


    const createNewsUrl = (title) => {
    return `/detail/${formatTitleForURL(title)}`;
};

const handleCopyLink = (title) => {
    const urlToCopy = createNewsUrl(title);
    navigator.clipboard.writeText(window.location.origin + urlToCopy)
        .then(() => {
            alert('Link copied to clipboard!'); // You can customize this alert
        })
        .catch((err) => {
            console.error('Failed to copy: ', err);
        });
};


    function decodeHtmlEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    return (
        <>
            {/* Politics News start */}
            <div className='politicsnew-section padding-50'>
                <div className='container-lg container-custom'>
                    <div className='pls-news'>
                        <div className='pls-left'>
                            <div className='row row-gap-4'>
                                <div className={`col-${isAdVisible ? 'md-9' : 'md-12'} row-gap`}>
                                    <div className='flex-box'>
                                        <div className='custom-heading ch-medium'>
                                            Politics
                                        </div>
                                        <Link className='custom-heading ch-small' to="/category/politics" >
                                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                                        </Link>
                                    </div>
                                    <div className='row row-gap-4'>
                                        {loadingSkeleton ? (
                                            <>
                                                {/* Skeleton Loader for Big News Item */}
                                                <div className='col-md-6'>
                                                    <div className='skeletant-news-img sh-413'></div>
                                                    <div className='skeletant-news-title sh-70'></div>
                                                    <div className='skeletant-news-text sh-40'></div>
                                                    <div className='activity mt-0'>
                                                        <div className='skeletant-news-date sh-20'></div>
                                                    </div>
                                                </div>

                                                {/* Skeleton Loader for Other News Items */}
                                                <div className='col-md-6'>
                                                    <div className='common-news-box row-gap'>
                                                        {[...Array(4)].map((_, index) => (
                                                            <div className='cnb-box' key={index}>
                                                                <div className='cnb-inner'>
                                                                    <div className='cnb-img'>
                                                                        <div className='skeletant-news-img sh-125'></div>
                                                                    </div>
                                                                    <div className='cnb-content'>
                                                                        <div className='custom-heading ch-medium'>
                                                                            <div className='skeletant-news-title sh-50'></div>
                                                                        </div>
                                                                        <div className='custom-text'>
                                                                            <div className='skeletant-news-text sh-40'></div>
                                                                        </div>
                                                                        <div className='custom-text'>
                                                                            <div className='skeletant-news-date sh-20'></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        ) : isLoading ? (
                                            <div>Loading...</div>
                                        ) : error ? (
                                            <div>{error}</div>
                                        ) : politicsNews.length > 0 ? (
                                            <>
                                                {/* Big news item on the left */}
                                                <div className='col-md-6'>
                                                    <Link to={`/detail/${formatTitleForURL(politicsNews[0].title)}`} className='pls-inner'>
                                                        <div className='zoom-img'>
                                                            <img src={politicsNews[0].image || Img.Plsnews} alt={politicsNews[0].title} />
                                                        </div>
                                                        <div className='custom-heading'>{politicsNews[0].title}</div>
                                                        <div className="activity mt-0">
                                                            {/* <div className='skeletant-news-text sh-40'>
                                                                <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                                <span className="comment">
                                                                    <Link onClick={() => handleCopyLink(politicsNews[0].title)} data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                                     <Icons.DocumentduplicateSvg />
                                                                    </Link>
                                                                </span>
                                                                <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                            </div> */}
                                                        </div>
                                                    </Link>
                                                </div>

                                                {/* Other news items on the right */}
                                                <div className='col-md-6'>
                                                    <div className='common-news-box row-gap'>
                                                        {politicsNews.slice(1).map((newsItem) => (
                                                            <div className='cnb-box' key={newsItem.id}>
                                                                <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                                    <div className='cnb-inner'>
                                                                        <div className='cnb-img'>
                                                                            <img src={newsItem.image || Img.P1} alt={newsItem.title} />
                                                                        </div>
                                                                        <div className='cnb-content'>
                                                                            <div className='custom-heading ch-medium'>
                                                                                {newsItem.title}
                                                                            </div>
                                                                            <div className='custom-text'>
                                                                                {newsItem.sort_title}
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div>No news available.</div>
                                        )}
                                    </div>
                                </div>

                                {isAdVisible && ads.length > 0 && (
                                    <div className='col-md-3'>
                                        <div className='adver-tisement d-none d-md-block'>
                                            <button className='close-btn' onClick={handleAdClose}>
                                                <Icons.XmarkSvg />
                                            </button>
                                            <Link className='adt-inner' to={ads[currentAdIndex].link}>
                                                <img src={ads[currentAdIndex].image} alt="Advertisement" />
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Politics News End */}
        </>
    );
};

export default Politicsnews;
