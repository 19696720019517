import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import { formatTitleForURL } from '../../function'; 

const Breakingnews = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [staticNewsItems, setStaticNewsItems] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Initially true for loading state

    const fetchBreakingNews = async () => {
        try {
            const response = await fetch(`${API_URL}get-breaking-news.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ /* Any POST data if required */ }),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNewsItems(data.breaking_news);
                setStaticNewsItems(data.non_breaking_news);
            } else {
                console.error('Error fetching news:', data.msg);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            // Hide skeleton loader after data is fetched or timeout expires
            setTimeout(() => {
                setIsLoading(false);
            }, 3000); // Show skeleton loader for 5 seconds
        }
    };

    useEffect(() => {
        fetchBreakingNews();
    }, []);

    const CustomPrevArrow = ({ onClick }) => (
        <div className="custom-arrow custom-prev" onClick={onClick}>
            <Icons.ChevronleftSvg />
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div className="custom-arrow custom-next" onClick={onClick}>
            <Icons.Chevronright />
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 3500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        beforeChange: (current, next) => setActiveIndex(next),
        prevArrow: <CustomPrevArrow onClick={() => setActiveIndex(activeIndex - 1)} />,
        nextArrow: <CustomNextArrow onClick={() => setActiveIndex(activeIndex + 1)} />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    

    const formatRelativeTime = (timestamp) => {
        const publishDate = new Date(timestamp);
        const now = new Date();
        const secondsDiff = Math.floor((now - publishDate) / 1000);
        const minutesDiff = Math.floor(secondsDiff / 60);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        if (daysDiff > 0) {
            return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
        } else if (hoursDiff > 0) {
            return `${hoursDiff} hour${hoursDiff > 1 ? 's' : ''} ago`;
        } else if (minutesDiff > 0) {
            return `${minutesDiff} minute${minutesDiff > 1 ? 's' : ''} ago`;
        } else {
            return 'Just now';
        }
    };

    return (
        <div className='breakingnews-section padding-50'>
            <div className='container-lg container-custom'>
                <div className='brk-inner'>
                    <div className='row row-gap-4'>
                        <div className='col-md-8 col-lg-8 col-xl-9'>
                            <div className='brk-left row-gap'>
                                <div className='custom-heading ch-medium'>
                                    <span className='icon'><Icons.ClockSvg /></span>Breaking news
                                </div>
                                <div className='brk-slider'>
                                    {isLoading ? (
                                        <div className="skeletant-news-container">
                                            <div className="skeletant-news-img sh-500" />
                                        <div className="skeletant-news-title sh-70" /> {/* Skeleton for title */}
                                        <div className="skeletant-news-text sh-50"  /> {/* Skeleton for sort title */}
                                            </div>
                                        ) : (
                                            <Slider {...settings}>
                                                {newsItems.map((item) => (
                                                    <Link key={item.id} to={`/detail/${formatTitleForURL(item.title)}`} >
                                                        <div className="news-card">
                                                            <div className='news-content'>
                                                                <img src={item.image} alt={item.title} className="news-image" />
                                                                <div className="custom-heading">
                                                                    {item.title}
                                                                </div>
                                                                <div className="custom-text">
                                                                    {item.sort_title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Slider>
                                        )}
                                    </div>

                            </div>
                        </div>
                        <div className='col-md-4 col-lg-4 col-xl-3'>
                            <div className='brk-right row-gap'>
                                <div className='brk-trading flex-box'>
                                    <Link className='custom-heading ch-medium'>
                                        <span className='icon'><Icons.ArrowtrendingupSvg /></span>Trending
                                    </Link>
                                    <Link className='custom-heading ch-small' to="/trending">
                                        View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                                    </Link>
                                </div>
                                <div className='brklist-trading'>
                                    <div className='brklt-inner row-gap'>
                                        {isLoading ? (
                                            Array.from({ length: 5 }).map((_, index) => (
                                                <div key={index} className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <div className='skeletant-news-img sh-80' />
                                                    </div>
                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>
                                                            <div className='skeletant-news-title sh-50' />
                                                        </div>
                                                        <div className='custom-text'>
                                                            <div className='skeletant-news-text sh-40' />
                                                        </div>
                                                        <div className='custom-text'>
                                                            <div className='skeletant-news-date sh-20' />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            staticNewsItems.map((item) => (
                                                <Link key={item.id} to={`/detail/${formatTitleForURL(item.title)}`}>
                                                    <div className='brklt-box'>
                                                        <div className='brkit-img'>
                                                            <img src={item.image} alt={item.title} />
                                                        </div>
                                                        <div className='brklt-content'>
                                                            <div className='custom-heading ch-medium'>
                                                                {item.title}
                                                            </div>
                                                            <div className='custom-text'>
                                                                {item.sort_title}
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Breakingnews;
