import React, { useState } from 'react';
import Slider from 'react-slick';

import { Link } from 'react-router-dom';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Cardslider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const images = [Img.News1, Img.News2, Img.News3, Img.News4];

    const CustomPrevArrow = ({ onClick, Icons }) => (
        <div className="custom-arrow custom-prev" onClick={onClick}>
            <Icons.ChevronleftSvg />
        </div>
    );

    const CustomNextArrow = ({ onClick, Icons }) => (
        <div className="custom-arrow custom-next" onClick={onClick}>
            <Icons.Chevronright />
        </div>
    );


    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: true,
        speed: 1000,
        initialSlide: 0,
        autoplay: true  ,
        beforeChange: (current, next) => setActiveIndex(next),
        prevArrow: <CustomPrevArrow onClick={() => setActiveIndex(activeIndex - 1)} Icons={Icons} />,
        nextArrow: <CustomNextArrow onClick={() => setActiveIndex(activeIndex + 1)} Icons={Icons} />,
        responsive: [   

            {
                breakpoint: 1300, // Tablet and above
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1100, // Tablet and above
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024, // Tablet and above
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600, // Mobile landscape
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480, // Mobile portrait
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='common-news-slider'>
            <div className='cnms-slider'>
                <div className="slider-container">
                    <Slider {...settings}>
                        {images.map((imgSrc, index) => {
                            const isActive = index === activeIndex;
                            return (
                                <div key={index} className={`slide ${isActive ? 'active' : 'inactive'}`}>
                                     <Link>

                                        <img
                                            src={imgSrc}
                                            alt={`Slide ${index}`}
                                            className={`slide-image ${isActive ? 'active' : 'inactive'}`}
                                        />
                                    </Link> 
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Cardslider;
