import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import * as Img from '../../components/Img'; 
import Icons from '../../components/icons'; 
import API_URL from '../../config/config'; 
import { formatTitleForURL } from '../../function';

const Marketnews = () => {
    const [marketNews, setMarketNews] = useState([]);
    const [ads, setAds] = useState([]);
    const [error, setError] = useState(null);
    const [currentAdIndex, setCurrentAdIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);
    const [isAdVisible, setIsAdVisible] = useState(true);

    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    useEffect(() => {
        const fetchMarketNews = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${API_URL}get-market-news.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({})
                });
                const data = await response.json();

                if (data.error === 0) {
                    setMarketNews(data.market_news);
                } else {
                    setError(data.msg || 'Failed to fetch market news.');
                }
            } catch (error) {
                setError('An error occurred while fetching market news.');
            } finally {
                setTimeout(() => {
                    setIsLoading(false);
                    setIsSkeletonVisible(false);
                }, 10000); // Ensure skeleton is visible for at least 10 seconds
            }
        };

        fetchMarketNews();
    }, []);

    useEffect(() => {
        let adInterval;
        if (ads.length > 0 && isAdVisible) {
            adInterval = setInterval(() => {
                setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
            }, 10000);
        }

        return () => clearInterval(adInterval);
    }, [ads, isAdVisible]);

    useEffect(() => {
    const fetchAds = async () => {
        try {
            const response = await fetch(`${API_URL}get-advertisements.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ height: 90, width: 728 }) // Correctly sending dimensions
            });
            const data = await response.json();

            if (data.error === 0) {
                setAds(data.ads);
            } else {
                setError(data.msg || 'Failed to fetch advertisements.');
            }
        } catch (error) {
            setError('An error occurred while fetching advertisements.');
        }
    };

    fetchAds();
}, []);


    return (
        <>
            {/* Market section start */}
            <div className='market-section padding-50'>
                <div className='container-lg'>
                    <div className='mrs-inner'>
                        <div className='flex-box'>
                            <div className='custom-heading ch-medium'>
                                Market
                            </div>
                        </div>
                    </div>

                    <div className='mrs-detail'>
                        <div className='row row-gap-4'>
                            <div className='col-md-8 border-right sp-e-4'>
                                {isSkeletonVisible ? (
                                    <div className='skeleton-loader'>
                                        {/* Main News Skeleton */}
                                        <div className='cnb-box'>
                                                    <div className='cnb-inner d-block'>
                                                        <div className='cnb-img'>
                                                            <div className='skeletant-news-img sh-476'></div>
                                                        </div>
                                                        <div className='cnb-content mt-3'>
                                                            <div className='custom-heading ch-medium'>
                                                                <div className='skeletant-news-title sh-105'></div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>

                                        {/* Other News Skeleton */}
                                        <div className='row row-gap-4'>
                                            {[...Array(4)].map((_, index) => (
                                                <div key={index} className='col-md-6'>
                                                    <div className='common-news-box'>
                                                        <div className='cnb-box'>
                                                            <div className='cnb-inner'>
                                                                <div className='cnb-img'>
                                                                    <div className='skeletant-news-img sh-125'></div>
                                                                </div>
                                                                <div className='cnb-content'>
                                                                    <div className='custom-heading ch-medium'>
                                                                        <div className='skeletant-news-title sh-50'></div>
                                                                    </div>
                                                                    <div className='custom-text'>
                                                                        <div className='skeletant-news-text sh-40'></div>
                                                                    </div>
                                                                    <div className='custom-text'>
                                                                        <div className='skeletant-news-date sh-20'></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : error ? (
                                    <div>{error}</div>
                                ) : marketNews.length > 0 ? (
                                    <>
                                        {/* Main news item */}
                                        <div className='mrs-img'>
                                            <Link to={`/detail/${formatTitleForURL(marketNews[0].title)}`}>
                                                <div className='mrs-img-inner'>
                                                    <img 
                                                        src={marketNews[0].image || Img.Market1} 
                                                        alt={marketNews[0].title} 
                                                        style={{ width: '100%', height: '100%' }} 
                                                    />
                                                </div>
                                                <div className='custom-heading d-block'>
                                                    {marketNews[0].title}
                                                </div>
                                            </Link>
                                        </div>

                                        {/* Other news items */}
                                        <div className='row row-gap-4'>
                                            {marketNews.slice(1, 5).map((newsItem) => (
                                                <div key={newsItem.id} className='col-md-6'>
                                                    <div className='common-news-box'>
                                                        <div className='cnb-box'>
                                                            <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                                <div className='cnb-inner'>
                                                                    <div className='cnb-img'>
                                                                        <img 
                                                                            src={newsItem.image || Img.Market4} 
                                                                            alt={newsItem.title} 
                                                                            style={{ width: '100%' }} 
                                                                        />
                                                                    </div>
                                                                    <div className='cnb-content'>
                                                                        <div className='custom-heading ch-medium'>
                                                                            {newsItem.title}
                                                                        </div>
                                                                        <div className='custom-text'>
                                                                            {newsItem.sort_title}
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <div>No market news available.</div>
                                )}
                            </div>

                            <div className='col-md-4 sp-s-4'>
                                {isSkeletonVisible ? (
                                    <>
                                        {[...Array(2)].map((_, index) => (
                                            <div key={index} className='common-news-box row-gap upcoming-news'>
                                                <div className='cnb-box'>
                                                    <div className='cnb-inner d-block'>
                                                        <div className='cnb-img'>
                                                            <div className='skeletant-news-img sh-274'></div>
                                                        </div>
                                                        <div className='cnb-content mt-3'>
                                                            <div className='custom-heading ch-medium'>
                                                                <div className='skeletant-news-title sh-50'></div>
                                                            </div>
                                                            <div className='custom-text'>
                                                                <div className='skeletant-news-text sh-40'></div>
                                                            </div>
                                                            <div className='custom-text'>
                                                                <div className='skeletant-news-date sh-20'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className='common-news-box row-gap upcoming-news'>
                                    {marketNews.slice(5, 7).map((newsItem) => {
                                        return (
                                            <div key={newsItem.id} className='cnb-box'>
                                                <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                    <div className='cnb-inner d-block'>
                                                        <div className='cnb-img'>
                                                            <img
                                                                src={newsItem.image || Img.Market2}
                                                                alt={newsItem.title}
                                                                style={{ width: '100%', height: '100%' }} 
                                                            />
                                                        </div>
                                                        <div className='cnb-content mt-3'>
                                                            <div className='custom-heading ch-medium'>
                                                                {newsItem.title}
                                                            </div>
                                                            <div className='custom-text'>
                                                                {newsItem.sort_title}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </div>
                                
                                )}
                            </div>
                        </div>

                        {isAdVisible && ads.length > 0 && (
                                   <div className='common-advertisement margin-50 mb-0 d-none d-md-block'>
                                        
                                            <button className='close-btn' onClick={handleAdClose}>
                                                <Icons.XmarkSvg />
                                            </button>
                                            <Link className='adt-inner' to={ads[currentAdIndex].link}>
                                                <img src={ads[currentAdIndex].image} alt="Advertisement" />
                                            </Link>
                                        </div>
                                    
                                )}
                                
                            
                    </div>
                </div>
            </div>
            {/* Market section end */}
        </>
    );
};

export default Marketnews;
