import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API_URL from "../config/config";

// Icons & images common files
import * as Img from '../components/Img';
import Icons from '../components/icons';

// sidebar css
import '../css/sidebar.css'

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [activeIndex, setActiveIndex] = useState(null); // Track the active item index for main menu
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null); // Track active dropdown item
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [categories, setCategories] = useState([]);

    // Handle dropdown toggle and mark it active
    const toggleDropdown = (index) => {
        setIsDropdownOpen(!isDropdownOpen);
        setActiveIndex(index); // Set the active index for the dropdown
        setActiveDropdownIndex(null); // Reset dropdown active when opening
    };

    // Handle main menu click
    const handleClick = (index) => {
        setActiveIndex(index);
        setActiveDropdownIndex(null); // Reset dropdown active when main item is clicked
        toggleSidebar(); // Close sidebar on click
    };

    // Handle dropdown item click
    const handleDropdownClick = (dropdownIndex) => {
        setActiveDropdownIndex(dropdownIndex);
        toggleSidebar(); // Close sidebar on click
    };
     useEffect(() => {
    // Fetch categories from API
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${API_URL}get-category-title.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}) // Add any necessary body parameters if required
        });
        const data = await response.json();
        if (data.error === 0) {
          setCategories(data.categories); // Assuming categories array in response
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

    return (
        <div className='main-sidebar'>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <div className="logo">
                        <Link><img src={Img.logo} alt="Logo" /></Link>
                    </div>
                    <button className="close-btn" onClick={toggleSidebar}>
                        <Icons.XmarkSvg />
                    </button>
                </div>
                <ul className={`sidebar-menu ${window.innerHeight < 440 ? 'scrollable' : ''}`}>

                    <li className={activeIndex === 0 ? 'active' : ''}>
                        <Link to="/" onClick={() => handleClick(0)}>Home</Link>
                    </li>
                    <li className={activeIndex === 1 ? 'active' : ''}>
                        <Link to="/About-us" onClick={() => handleClick(1)}>About Us</Link>
                    </li>
                     <li className={activeIndex === 2 ? 'active' : ''}>
                        <Link onClick={() => toggleDropdown(2)}>
                            Category {isDropdownOpen ? <Icons.ChevronupSvg /> : <Icons.ChevrondownSvg />}
                        </Link>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu">
                                {categories.map((category, index) => (
                                    <li key={index} className={activeDropdownIndex === index ? 'active' : ''}>
                                        <Link
                                            to={`/category/${encodeURIComponent(category.title.toLowerCase())}`}
                                            onClick={(e) => { /* handle click logic if needed */ }}
                                        >
                                            {category.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}

                    </li>
                    <li className={activeIndex === 3 ? 'active' : ''}>
                        <Link to="/Privacy-policy" onClick={() => handleClick(3)}>Privacy Policy</Link>
                    </li>
                    <li className={activeIndex === 4 ? 'active' : ''}>
                        <Link to="/Terms-condition" onClick={() => handleClick(4)}>Terms & Conditions</Link>
                    </li>
                    {/* <li className={activeIndex === 5 ? 'active' : ''}>
                        <Link onClick={() => handleClick(5)}>Post</Link>
                    </li> */}
                    <li className={activeIndex === 6 ? 'active' : ''}>
                        <Link to="/Contact-us" onClick={() => handleClick(6)}>Contact Us</Link>
                    </li>
                    <li className={activeIndex === 7 ? 'active' : ''}>
                        <Link to="/Sitemap" onClick={() => handleClick(7)}>Sitemap</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
