import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import Commoninformation from '../Pagehome/Commoninformation';
import { formatTitleForURL } from '../../function';



const Detail = () => {
    const { title } = useParams();
    const [newsItem, setNewsItem] = useState(null);
    const [recentNews, setRecentNews] = useState([]);
    const [previewNews, setPreviewNews] = useState([]);
    const [nextNews, setNextNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]); 
    const [isPreviewDropdownOpen, setIsPreviewDropdownOpen] = useState(false);
    const [isNextDropdownOpen, setIsNextDropdownOpen] = useState(false);

    useEffect(() => {
        const fetchNewsDetails = async () => {
            try {
                const response = await fetch(`${API_URL}get-news-detail.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ title }),
                });
                const data = await response.json();

                if (data.error === 0) {
                    setNewsItem(data.news);
                    setRecentNews(data.recent_news);
                    setPreviewNews(data.previous_news);
                    setNextNews(data.next_news);
                } else {
                    setError(data.msg || 'Failed to fetch news details.');
                }
            } catch (error) {
                setError('An error occurred while fetching news details.');
            } finally {
                setTimeout(() => {
                setIsLoading(false);
            }, 3000);
            }
        };

        fetchNewsDetails();
    }, [title]);

    useEffect(() => {
    const fetchCategories = async () => {
        try {
            const response = await fetch(`${API_URL}get-category-title.php`); // Update with your API endpoint
            const data = await response.json();
            if (data.error === 0) {
                setCategories(data.categories);
            } else {
                setError(data.msg || 'Failed to fetch categories.');
            }
        } catch (error) {
            setError('An error occurred while fetching categories.');
        }
    };

    fetchCategories();
}, []);


    const togglePreviewDropdown = () => setIsPreviewDropdownOpen(!isPreviewDropdownOpen);
    const toggleNextDropdown = () => setIsNextDropdownOpen(!isNextDropdownOpen);

      const currentUrl = window.location.href;
     const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(newsItem?.title || '')}`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(newsItem?.title || '')}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(newsItem?.title || '')} - ${encodeURIComponent(currentUrl)}`;

    const renderSkeleton = () => (
        <div className='skeleton-loader'>
            <div className="skeletant-news-img sh-500" />
            <div className="skeletant-news-title sh-70" />
            <div className="skeletant-news-text sh-50" />
            <div className="skeletant-news-date sh-20" />
        </div>
    );

    return (
        <>
            <Commoninformation />
            <div className='container-lg'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Detail
                        </li>
                    </ol>
                </nav>
            </div>
            <div className='detail-page padding-50'>
                <div className='container-lg'>
                    <div className='row row-gap-4'>
                        <div className='col-md-7 col-lg-7 col-xl-8'>
                            <div className='social-shearing'>
                                <ul>
                                     <li>
                                        <a href={fbShareUrl} target="_blank" rel="noopener noreferrer" className='fb-link'>
                                            <img src={Img.Fb} alt='facebook ' /> 
                                            <div className="icon-tag">Facebook Share</div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className='tw-link'>
                                            <img src={Img.tw} alt='twitter ' /> 
                                            <div className="icon-tag">Twitter Share</div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={telegramShareUrl} target="_blank" rel="noopener noreferrer" className="tl-link">
                                            <img src={Img.Tel} alt='telegram ' /> 
                                            <div className="icon-tag">Telegram Share</div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer" className="wh-link">
                                            <img src={Img.whatsapp} alt='whatsapp ' /> 
                                            <div className="icon-tag">whatsapp Share</div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='dp-inner'>
                                {isLoading ? (
                                    renderSkeleton()
                                ) : (
                                    newsItem && (
                                        <>
                                            <img src={newsItem.image || Img.Plsnews} alt={newsItem.title} />
                                            <div className='custom-heading'>{newsItem.title}</div>
                                            <div className='custom-text ct-small' dangerouslySetInnerHTML={{ __html: newsItem.description }} />
                                            
                                        </>
                                    )
                                )}

                                {/* Preview and Next */}
                                <div className='detail-next-preview'>
                                    {previewNews.length > 0 && (
                                        <div className='detail-preview' onClick={togglePreviewDropdown}>
                                            <div className='dnp-title'>
                                                <span><Icons.ChevronleftSvg /></span> Previous
                                                <div className="custom-text ct-small">{previewNews[0]?.title}</div>
                                            </div>
                                            <div className={`dropdown-menu ${isPreviewDropdownOpen ? 'open' : ''}`}>
                                                <ul>
                                                    {previewNews.map(news => (
                                                        <li key={news.id}>
                                                            <Link to={`/detail/${formatTitleForURL(news.title)}`}>{news.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}

                                    {nextNews.length > 0 && (
                                        <div className='detail-next text-end' onClick={toggleNextDropdown}>
                                            <div className='dnp-title text-end'>
                                                Next <span><Icons.Chevronright /></span>
                                                <div className="custom-text ct-small">{nextNews[0]?.title}</div>
                                            </div>
                                            <div className={`dropdown-menu ${isNextDropdownOpen ? 'open' : ''}`}>
                                                <ul>
                                                    {nextNews.map(news => (
                                                        <li key={news.id}>
                                                            <Link to={`/detail/${formatTitleForURL(news.title)}`}>{news.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='col-md-5 col-lg-5 col-xl-4'>
                            <div className='common-news-box row-gap'>
                                <div className='flex-box'>
                                    <div className='custom-heading ch-medium'>Recent Posts</div>
                                </div>
                                {isLoading ? (
                                    Array.from({ length: 5 }).map((_, index) => (
                                        <div key={index} className='cnb-box'>
                                            <div className='cnb-inner'>
                                                <div className='cnb-img'>
                                                    <div className='skeletant-news-img sh-125' />
                                                </div>
                                                <div className='cnb-content'>
                                                    <div className='custom-heading ch-medium'>
                                                        <div className='skeletant-news-title sh-50' />
                                                    </div>
                                                    <div className='custom-text'>
                                                        <div className='skeletant-news-text sh-40' />
                                                    </div>
                                                    <div className='custom-text'>
                                                        <div className='skeletant-news-date sh-20' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    recentNews.map(news => (
                                        <div className='cnb-box' key={news.id}>
                                            <Link to={`/detail/${formatTitleForURL(news.title)}`}>
                                                <div className='cnb-inner'>
                                                    <div className='cnb-img'>
                                                        <img src={news.image_name ? `${news.image}` : Img.P1} alt={news.title} />
                                                    </div>
                                                    <div className='cnb-content'>
                                                        <div className='custom-heading ch-medium'>{news.title}</div>
                                                        <div className='custom-text'>{news.sort_title}</div>
                                                        
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                )}

                                {/* Quick Links Section */}
                                <div className="quick-links-section">
                                    <div className="quick-links-inner">
                                        <div className="custom-heading ch-medium">Quick Links</div>
                                        
                                           <div className="quick-links-buttons">
                                                {categories.map((category) => (
                                                    <Link className="quick-link-btn common-btn-item cbi-outline" key={category.id} to={`/category/${formatTitleForURL(category.title)}`}>
                                                        <div className="quick-link-button">
                                                            {category.title}
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Detail;
