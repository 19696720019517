import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Icons & images common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import { formatTitleForURL } from '../../function';

const Sportnews = () => {
    const [sportsNews, setSportsNews] = useState([]);
    const [error, setError] = useState(null);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true); // Skeleton loader state
    const [isLoading, setIsLoading] = useState(false); // Loading state

    // Fetch sports news from the API
    useEffect(() => {
        const fetchSportsNews = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`${API_URL}get-sports-news.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({})
                });
                const data = await response.json();

                if (data.error === 0) {
                    setSportsNews(data.sports_news);
                } else {
                    setError(data.msg || 'Failed to fetch sports news.');
                }
            } catch (error) {
                setError('An error occurred while fetching sports news.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchSportsNews();

        // Set timeout for minimum loading skeleton duration
        const timer = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 10000); // 10 seconds

        return () => clearTimeout(timer);
    }, []);

    // Clear loading skeleton after fetching
    useEffect(() => {
        if (!loadingSkeleton && !isLoading) {
            setLoadingSkeleton(false);
        }
    }, [isLoading, loadingSkeleton]);


    return (
        <>
            {/* Sport Section start */}
            <div className='sport-news-section padding-50'>
                <div className='container-lg row-gap'>
                    <div className='flex-box'>
                        <div className='custom-heading ch-medium'>Sports</div>
                        <Link className='custom-heading ch-small' to="/category/sports">
                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                        </Link>
                    </div>

                    <div className='spns-inner'>
                        {loadingSkeleton ? (
                            <div>
                                <div className='skeletant-news-img' style={{ height: '386px' }}></div>
                            </div>
                        ) : error ? (
                            <div>{error}</div>
                        ) : sportsNews.length > 0 ? (
                            <>
                                {/* Big news item */}
                                <Link to={`/detail/${formatTitleForURL(sportsNews[0].title)}`}>
                                    <img src={sportsNews[0].image || Img.Sport} alt={sportsNews[0].title} />
                                    <div className='custom-heading'>{sportsNews[0].title}</div>
                                </Link>
                            </>
                        ) : (
                            <div>No sports news available.</div>
                        )}
                    </div>

                    <div className='row row-gap-4'>
                        <div className='col-md-7 col-lg-8'>
                            <div className='common-news-box row-gap'>
                                {loadingSkeleton ? (
                                    <div className='skeletant-news-loader'>
                                        {/* Skeleton Loader for other news items */}
                                        {[...Array(1)].map((_, index) => (
                                            <div key={index} className='cnb-box latest-news'>
                                                <div className='cnb-inner d-block'>
                                                    <div className='row row-gap-4'>
                                                        <div className='col-md-7'>
                                                            <div className='cnb-img'>
                                                                <div className='skeletant-news-img sh-342'></div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading'>
                                                                    <div className='skeletant-news-title sh-105'></div>
                                                                </div>
                                                                <div className='custom-text'>
                                                                    <div className='skeletant-news-text sh-40'></div>
                                                                </div>
                                                                <div className='custom-text'>
                                                                    <div className='skeletant-news-date sh-20'></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    sportsNews.slice(1, 2).map((newsItem) => (
                                        <div key={newsItem.id} className='cnb-box latest-news'>
                                            <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                <div className='cnb-inner d-block'>
                                                    <div className='row row-gap-4'>
                                                        <div className='col-md-7'>
                                                            <div className='cnb-img'>
                                                                <img src={newsItem.image || Img.Sporttwo} alt={newsItem.title} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading'>{newsItem.title}</div>
                                                                <div className='custom-text'>{newsItem.sort_title}</div>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>

                        <div className='col-md-5 col-lg-4'>
                            <div className='common-news-box row-gap'>
                                {loadingSkeleton ? (
                                    <div className='skeletant-news-loader'>
                                        {[...Array(2)].map((_, index) => (
                                            <div key={index} className='cnb-box'>
                                                <div className='cnb-inner'>
                                                    <div className='cnb-img'>
                                                        <div className='skeletant-news-img sh-80'></div>
                                                    </div>
                                                    <div className='cnb-content'>
                                                        <div className='custom-heading ch-medium'>
                                                            <div className='skeletant-news-title sh-50'></div>
                                                        </div>
                                                        <div className='custom-text'>
                                                            <div className='skeletant-news-text sh-40'></div>
                                                        </div>
                                                        <div className='custom-text'>
                                                            <div className='skeletant-news-date sh-20'></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    sportsNews.slice(2, 4).map((newsItem) => (
                                        <div key={newsItem.id} className='cnb-box'>
                                            <Link to={`/detail/${formatTitleForURL(newsItem.title)}`}>
                                                <div className='cnb-inner'>
                                                    <div className='cnb-img'>
                                                        <img src={newsItem.image || Img.Sportthree} alt={newsItem.title} />
                                                    </div>
                                                    <div className='cnb-content'>
                                                        <div className='custom-heading ch-medium'>{newsItem.title}</div>
                                                        <div className='custom-text'>{newsItem.sort_title}</div>
                                                        
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sport Section end */}
        </>
    );
};

export default Sportnews;
