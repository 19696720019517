import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Icons from '../../components/icons';
import API_URL from "../../config/config";
import { formatTitleForURL } from '../../function';

const Topstoryslider = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loadingSkeleton, setLoadingSkeleton] = useState(true); // New state for skeleton loader

    const fetchTopStories = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API_URL}get-top-story.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });
            const data = await response.json();

            if (data.error === 0) {
                setNewsItems(data.non_breaking_news);
            } else {
                setError(data.msg || 'Failed to fetch top stories.');
            }
        } catch (error) {
            setError('An error occurred while fetching top stories.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTopStories();
    }, []);

    // Set up timeout to hide skeleton loader after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingSkeleton(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <>
            {/* Top Story start */}
            <div className="top-story-section padding-50">
                <div className='container-fluid'>
                    <div className="custom-heading">Top Stories</div>
                    <div className="custom-subheading ch-small">Latest Updates</div>

                    <div className="tps-inner">
                        {loadingSkeleton ? (
                            <Slider {...settings}>
                                {/* Skeleton Loader for Stories */}
                                {[...Array(5)].map((_, index) => (
                                    <div className="common-news-box" key={index}>
                                        <div className='cnb-box'>
                                            <div className='cnb-inner'>
                                                <div className="cnb-img">
                                                    <div className='skeletant-news-img sh-364'></div>
                                                </div>
                                                <div className="cnb-content">
                                                    <div className="custom-heading">
                                                        <div className='skeletant-news-title sh-70'></div>
                                                    </div>
                                                    <div className="custom-text">
                                                        <div className='skeletant-news-text sh-40'></div>
                                                    </div>
                                                   
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        ) : isLoading ? (
                            <div>Loading...</div>
                        ) : error ? (
                            <div>{error}</div>
                        ) : (
                            <Slider {...settings}>
                                {newsItems.map((item) => (
                                    <div className="common-news-box" key={item.id}>
                                        <div className='cnb-box'>
                                            <Link to={`/detail/${formatTitleForURL(item.title)}`}>
                                                <div className="cnb-inner">
                                                    <div className="cnb-img">
                                                        <img src={item.image} alt={item.title} />
                                                    </div>
                                                    <div className="cnb-content">
                                                        <div className="custom-heading">
                                                            {item.title}
                                                        </div>
                                                        <div className="custom-text">
                                                            {item.sort_title}
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        )}
                    </div>
                </div>
            </div>
            {/* Top Story End */}
        </>
    );
};

export default Topstoryslider;
