import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import * as Img from '../../components/Img'; // Add your other imports
import Icons from '../../components/icons';
import API_URL from "../../config/config";

const Luxuryproducts = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            const response = await fetch(`${API_URL}get-gallery.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });

            const data = await response.json();
            if (data.error === 0) {
                setImages(data.ads);
            }
        };

        fetchImages();
    }, []);

    return (
        <div className='luxury-products-section padding-50'>
            <div className='container-lg'>
                <div className='lps-inner row-gap'>
                    <div className='flex-box'>
                        <div className='custom-heading ch-medium'>Gallery</div>
                        <Link className='custom-heading ch-small'>
                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                        </Link>
                    </div>

                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 400: 2, 750: 2, 900: 3 }}>
                        <Masonry columnsCount={3} gutter="15px">
                            {images.map((img, index) => (
                                <div className="masonry-item" key={img.id}>
                                <img src={img.image} alt={`Gallery item ${index + 1}`} />
                                    <div>
                                        <Link>
                                            
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </div>
    );
};

export default Luxuryproducts;
