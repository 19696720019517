import React, { useState } from 'react';
import Commoninformation from '../Pagehome/Commoninformation';
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';
import API_URL from "../../config/config";

const Contactus = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/post-contact.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    cname: formData.name,
                    cmail: formData.email,
                    cmsg: formData.message,
                }),
            });

            const result = await response.json();

            if (result.error === 0) {
                alert(result.msg); // Show success message
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                });
            } else {
                alert(result.msg); // Show error message
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('An error occurred while submitting the form.');
        }
    };

    return (
        <>
            <Commoninformation />
            <div className='container-lg'>
                {/*breadcrunb start*/}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Contact us
                        </li>
                    </ol>
                </nav>
                {/*breadcrunb End*/}
            </div>
            <div className='padding-50 pt-4 contact-box'>
                <div className='container-lg'>
                    <div className='flex-box justify-center mb-4'>
                        <div className='custom-heading text-center'>
                            Contact Us
                        </div>
                    </div>

                    <div className="contact-us-container">
                        <div className="contact-info">
                            <div className='custom-heading white'>Get In Touch</div>
                            <div className='custom-text ct-medium'>
                                Have a question or want to get in touch with us? Feel free to reach out via the form below.
                            </div>

                            <ul className="contact-details">
                                <li><span><Icons.EnvelopeSvg /></span> <a href="mailto:contact@forbes.com">contact@forbes.com</a></li>
                                <li><span><Icons.DevicephonemobileSvg /></span>  <a href="tel:+12345678900">+1 234 567 8900</a></li>
                                <li><span><Icons.MappinSvg /></span>  123 News Street, Media City, USA</li>
                            </ul>
                        </div>

                        <div className="contact-form">
                            <form onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="subject">Subject</label>
                                    <input
                                        type="text"
                                        id="subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                        rows="5"
                                    ></textarea>
                                </div>
                                <button type="submit" className="common-btn-item cbi-solid">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contactus;
